import React from 'react'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import "./price-and-features.css"

const PriceAndFeatures = (props) => {
    const image = getImage(props.imageSrc);
    return (
        <div id="priceAndFeatures" className="price_and_features-container bg-brand-green">
            <div className="grid">
                <div className="f-dir-col f-justify-center price_and_features-wrapper">
                    <div className="image-wrapper pt-40">
                        <GatsbyImage image={image} alt={props.altText} />         
                    </div>
                </div>
                <div className="f-dir-col f-align-end f-justify-between content-wrapper">
                    <div className="f-dir-col f-align-center f-justify-between">
                        <div className="f-dir-col f-align-center information-wrapper">
                            <div className="f-dir-row f-align-center f-justify-center mb-30 text-wrapper">
                                <h2 className="align-right uppercase">The<br></br>Tea Set</h2>
                                <div className="divider mlr-20"></div>
                                <h3 className="grey-600">{props.price}</h3>
                            </div>
                            <a className="btn btn-primary" href="https://shop.fabrica-aster.com/products/tea-set">buy now</a>
                            <p className="subhead mt-20">Beautiful. Functional. Timeless. Handmade in America. </p>
                        </div>
                        <div className="f-dir-col f-align-center includes-wrapper">
                            <img className="desktop-silouette" src="img/product-silouettes.svg" alt="Product Siloettes"/>
                            <img className="mobile-silouette" src="img/mobile-silouette.svg" alt="Product Siloettes"/>
                            <h5 className="align-center fw-300 grey-400 pt-20">The set includes: tray, water pipe, mill, container, tealight, wick holder, wick, and candle. </h5>
                            <h5 className="align-center fw-300 grey-400">The set includes: water pipe, mill, container, tealight, wick holder, tray, candle&nbsp;and&nbsp;wick.</h5>
                        </div>   
                    </div> 
                </div>
            </div>
        </div>
    )
}

export default PriceAndFeatures

