import React from 'react'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import "./hero.css"

const Hero = (props) => {
    const image = getImage(props.heroImage);
    return (
        <div className="hero-container bg-brand-green">
            <div className="grid hero-wrapper">
                <div className="f-dir-col f-justify-center f-align-center hero-wrapper">
                    <div className="image-wrapper pt-80">
                        <GatsbyImage image={image} alt={props.altText} />         
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Hero

