import React from 'react'
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image"
import "./image-banner.css"

const ImageBanner = ({items, itemClass, annotation}) => {
  const BannerImages = items.map((item, index) => {
    const { image, mobileImage, altText } = item;
    let src;
    if(mobileImage != null) {
      src = withArtDirection(getImage(image), [
        {
          media: "(max-width: 600px)",
          image: getImage(mobileImage)
        }
      ]);
    } else {
      src = getImage(image);
    }

    return (
      <GatsbyImage image={src} alt={altText} key={index}/>
    )
  });

  return (
    <div className={`image-section-container item-${itemClass} bg-black`}>
      <div className={`image-section-wrapper ${items.length == 2 ? 'col-2' : ''}`}>
        {BannerImages}
      </div>
      {
        annotation &&
        <div className={`annotation-wrapper bg-black p-30`}>
          <p className="p1 fw-300">{annotation}</p>
        </div>
      }
    </div>
    
  )
}

export default ImageBanner

