import React from 'react'
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image"
import './image-with-text.css'


const ImageWithText = (props) => {
  let image;
  if(props.mobileImage != null) {
    image = withArtDirection(getImage(props.image), [
      {
        media: "(max-width: 600px)",
        image: getImage(props.mobileImage)
      }
    ]);
  } else {
    image = getImage(props.image);
  }
  

  return (
    <div className={`image_with_text-container ${props.backgroundColor}`} >
      <div className="grid">
        {
          props.leadInParagraph &&
          <div className="f-align-center f-justify-center text-wrapper">
            <h2 className="lead-in pt-60 pb-80 fw-300 grey-200">
              {props.leadInParagraph}
            </h2>
          </div>
        }
        <div className={`col-2 ptb-100 content-wrapper ${props.itemClass}`}>
            <div className="f-dir-col f-justify-center f-align-center text-wrapper">
              <div className="f-dir-col f-align-start f-justify-center text">
                <h2 className="mb-10 fw-300 grey-100">{props.headline}</h2>
                <p className="p1 grey-500 fw-300">{props.subhead}</p>
              </div>
            </div>
            <div className="image-wrapper">
            <GatsbyImage image={image} objectFit="contain" alt={props.altText} />
            </div>
        </div>
      </div>
    </div>
    
  )
}

export default ImageWithText
