import React from 'react'
import "./section-title.css"

const SectionTitle = ({headline, subhead}) => {
    return (
        <div className="section_title-container bg-grey-700">
            <div className="grid">
                <div className="title-wrapper">
                    <div className="f-dir-row f-align-center f-justify-end headline-wrapper">
                        <h2 className="h1 uppercase">{headline}</h2>
                    </div>
                    <div className="f-dir-row f-align-center f-justify-center divider-wrapper">
                        <div className="divider"/>
                    </div>
                    <div className="f-dir-row f-align-center f-justify-start subhead-wrapper">
                        <h3 className="h2 grey-600">{subhead}</h3>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SectionTitle

