import React from 'react'
import { graphql } from "gatsby"
import Layout from '../components/layout'
import Seo from '../components/seo'
import Hero from '../components/home/hero/hero'
import ImageBanner from '../components/home/image-banner/image-banner'
import BigTextSection from '../components/home/big-text-section/big-text-section'
import SectionTitle from '../components/home/section-title/section-title'
import ImageWithText from '../components/home/image-with-text/image-with-text'
import PriceAndFeatures from '../components/home/price-and-features/price-and-features'



const IndexPage = ({data}) => {
  const frontmatter = data.allMarkdownRemark.edges[0].node.frontmatter;
  return(
    <Layout
      pageClass="home"
    >
      <Seo title="The Tea Set" keywords={[`gatsby`, `application`, `react`]} />
      <Hero
        heroImage={frontmatter.heroImage[0].image}
        altText={frontmatter.heroImage[0].altText}
      />
      <SectionTitle
        headline={frontmatter.headlineBanners[0].headline}
        subhead={frontmatter.headlineBanners[0].subhead}
      />
      <ImageBanner
        items={[frontmatter.imageBanners[0]]}
        itemClass="0"
      />
      <BigTextSection
        itemClass="0"
      >
        <h2 className="big desktop-text align-left">{frontmatter.bigText[0].firstLine}</h2>
        <h2 className="big desktop-text align-center">{frontmatter.bigText[0].secondLine}</h2>
        <h2 className="big desktop-text align-right">{frontmatter.bigText[0].thirdLine}</h2>
        <h2 className="big mobile-text">{frontmatter.bigText[0].firstLine + ` ` + frontmatter.bigText[0].secondLine  + ` ` + frontmatter.bigText[0].thirdLine}</h2>
      </BigTextSection>
      <ImageBanner
        items={[frontmatter.imageBanners[1]]}
        itemClass="1"
      />
      <BigTextSection
        itemClass="1"
      >
        <h2 className="big align-right fw-100">{frontmatter.bigText[1].firstLine}</h2>
        <h2 className="big align-right fw-100">{frontmatter.bigText[1].secondLine}</h2>
        <h2 className="big align-right fw-100">{frontmatter.bigText[1].thirdLine}</h2>
        
      </BigTextSection>
      <ImageBanner
        annotation={frontmatter.annotations[0].annotation}
        items={[frontmatter.imageBanners[2]]}
        itemClass="2"
      />
      <ImageBanner
        items={[frontmatter.imageBanners[3]]}
        itemClass="3"
      />
      <BigTextSection
        itemClass="2"
      >
        <h2 className="big align-left fw-100">{frontmatter.bigText[2].firstLine}</h2>
        <h2 className="big align-left fw-100">{frontmatter.bigText[2].secondLine}</h2>
      </BigTextSection>
      <ImageBanner
        items={[frontmatter.imageBanners[4]]}
        itemClass="4"
      />
      <ImageBanner
        annotation={frontmatter.annotations[1].annotation}
        items={[frontmatter.imageBanners[5], frontmatter.imageBanners[6]]}
        itemClass="5"
      />
      <BigTextSection
        itemClass="3"
      >
        <h2 className="big align-left desktop-text fw-100">{frontmatter.bigText[3].firstLine}</h2>
        <h2 className="big align-left desktop-text ml-150 fw-100">{frontmatter.bigText[3].secondLine}</h2>
        <h2 className="big align-left desktop-text ml-250 fw-100">{frontmatter.bigText[3].thirdLine} <span style={{fontSize: 35}}>{frontmatter.bigText[3].fourthLine}</span></h2>
        <h2 className="big mobile-text fw-100">{frontmatter.bigText[3].firstLine + ` ` + frontmatter.bigText[3].secondLine + ` ` + frontmatter.bigText[3].thirdLine + ` ` + frontmatter.bigText[3].fourthLine}</h2>
      </BigTextSection>
      <ImageBanner
        annotation={frontmatter.annotations[2].annotation}
        items={[frontmatter.imageBanners[7], frontmatter.imageBanners[8]]}
        itemClass="6"
      />
      <BigTextSection
        itemClass="4"
      >
        <h2 className="big desktop-text fw-100">{frontmatter.bigText[4].firstLine}</h2>
        <h2 className="big desktop-text fw-100">{frontmatter.bigText[4].secondLine}</h2>
        <h2 className="big mobile-text fw-100">{frontmatter.bigText[4].firstLine + ` ` + frontmatter.bigText[4].secondLine}</h2>
      </BigTextSection>
      <ImageBanner
        items={[frontmatter.imageBanners[9]]}
        itemClass="7"
      />
      <SectionTitle
        headline={frontmatter.headlineBanners[1].headline}
        subhead={frontmatter.headlineBanners[1].subhead}
      />
      {
        frontmatter.twoColumnSection.map((item, index) => {
          return (
            <ImageWithText
              backgroundColor="bg-black"
              image={item.image}
              mobileImage={item.mobileImage}
              altText={item.altText}
              headline={item.headline}
              leadInParagraph={item.leadInParagraph}
              subhead={item.subhead}
              key={`twoColumn-item-${index}`}
              itemClass={`item-${index}`}
            />
          )
        })
      }
      <SectionTitle
        headline={frontmatter.headlineBanners[2].headline}
        subhead={frontmatter.headlineBanners[2].subhead}
      />
      <ImageBanner
        items={[frontmatter.imageBanners[10]]}
        itemClass="8"
      />
      <BigTextSection
        itemClass="5"
      >
        <h2 className="align-left fw-300">{frontmatter.annotations[3].annotation}</h2>
      </BigTextSection>
      <PriceAndFeatures
        imageSrc={frontmatter.priceAndFeatures[0].image}
        altText={frontmatter.priceAndFeatures[0].altText}
        price={frontmatter.priceAndFeatures[0].price}
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  query HomePageQuery {
    allMarkdownRemark(filter: {frontmatter: {title: {eq: "Home"}}}) {
      edges {
        node {
          frontmatter {
            annotations {
              annotation
            }
            bigText {
              firstLine
              secondLine
              thirdLine
              fourthLine
            }
            headlineBanners {
              headline
              subhead
            }
            heroImage {
              altText
              image {
                childImageSharp {
                  gatsbyImageData (
                    quality: 100
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                  )
                }
              }
            }
            imageBanners {
              altText
              image {
                childImageSharp {
                  gatsbyImageData (
                    quality: 100
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                  )
                }
              }
              mobileImage {
                childImageSharp {
                  gatsbyImageData (
                    quality: 100
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                  )
                }
              }
            }
            twoColumnSection {
              leadInParagraph
              headline
              subhead
              altText
              image {
                childImageSharp {
                  gatsbyImageData (
                    quality: 100
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                  )
                }
              }
              mobileImage {
                childImageSharp {
                  gatsbyImageData (
                    quality: 100
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                  )
                }
              }
            }
            priceAndFeatures {
              altText
              price
              image {
                childImageSharp {
                  gatsbyImageData (
                    quality: 100
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                  )
                }
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage

