import React from 'react'
import './big-text-section.css'

function BigTextSection(props) {
    return(
        <div className={`big-text-container item-${props.itemClass} ptb-100 plr-50 bg-black`}>
            <div className="grid">
                <div className={`f-dir-col f-align-center text-wrapper`}>
                    {props.children}
                </div>
            </div>
        </div>
    )

}

export default BigTextSection